<template>
	<div>
		<myheader></myheader>
			<div id="details">
			<div class="bg"></div>
			<div class="details">
				<!--  -->
				<div class="title">
					<p>{{view.pid | type}}</p>
					<img src="../assets/other/right.png" alt="">
					<p>{{view.goods_name}}</p>
				</div>
				<!--  -->
				<div class="detail">
					<div class="dleft">
						<!-- <div v-if="view.video">

						</div> -->
						<el-carousel :autoplay="!view.video" height="276px">
							<el-carousel-item  v-if="view.video">
								<video :src="view.video" controls="controls" height="276px"  width="490px">
									your browser does not support the video tag
								</video>
						    </el-carousel-item>
						    <el-carousel-item v-for="(item,i) in view.attachment" :key="i">
								<img :src="item" class="pic">
						    </el-carousel-item>
						</el-carousel>
						<div class="calendar" style="height:429px;">
							<el-calendar id="">
								<!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
								<template slot="dateCell" slot-scope="{date, data}">
									<!--自定义内容-->
									<div @click="show(data.day)">
										<div class="calendar-day" style="text-align: center">
										<p> {{ data.day.split('-').slice(1).join('-') }}</p>
										<p style=" font-weight: 600;color:red">{{getprice(data.day).price | stu}}</p>
										<template v-if="getprice(data.day).price">
											<!-- <p style="color:green" v-if="getprice(data.day).is_team==1">已满团</p> -->
											<!-- <template v-if="getprice(data.day).is_team==1">
												<p style="color:red" v-if="inventoryNumber(getprice(data.day)) > 5">
													 已成团
												</p>
												<p style="color:red" v-else-if="inventoryNumber(getprice(data.day))>0 && inventoryNumber(getprice(data.day))<=5">
													余位{{inventoryNumber(getprice(data.day))}}人
												</p>
												<p style="color:red" v-else>
													 已满团
												</p>
											</template> -->
											<!-- <template>
												<div v-for="(i, index) in canSelectDate(data.day)" :key="index">
													{{i.is_team}}
													{{inventoryNumber(i)}}
												</div>
											</template> -->
											<template v-if="canSelectDate(data.day).some(i => i.is_team==1)">
												<p style="color:red" v-if="canSelectDateTotal(data.day) > 6">已成团</p>
												<p style="color:red" v-else-if="canSelectDateTotal(data.day) <= 6 && canSelectDateTotal(data.day) > 0">余位{{canSelectDateTotal(data.day)}}人</p>
												<p style="color:red" v-else>已满团</p>
											</template>
											<p style="color:blue" v-else-if="canSelectDate(data.day).every(i => i.is_team==0)">可报名 </p>
											<p v-else>已取消</p>
										</template>

										</div>
									</div>
								</template>
							</el-calendar>
						</div>

					</div>
					<div class="dright">
						<div class="info">
							<h3>{{view.goods_name | wuellipsis(50)}} </h3>
							<div class="label">
								<ul>
									<li v-for="(it,i) in view.tags" :key="i">{{it}}</li>

								</ul>
							</div>

							<div class="star">
								<p>
									<span>难度系数</span>
									<el-rate  v-model="view.difficulty_number" disabled></el-rate>
								</p>
								<p>
									<span>强度系数</span>
									<el-rate v-model="view.intensity_number" disabled></el-rate>
								</p>

								<p>
									<span>风景系数</span>
									<el-rate v-model="view.scenery_number" disabled></el-rate>
								</p>
								<p>
									<span>趣味系数 </span>
									<el-rate  v-model="view.interesting_number" disabled></el-rate>
								</p>
							</div>

							<div class="adress flex-between">
								<p>
									<img src="../assets/other/adress.png" alt="">
									<span class="t">集合点：</span>
									<span>{{view.place_address}}</span>

								</p>
								<p>
									<img src="../assets/other/people.png" alt="">
									<span class="t">已参加人数：</span>
									<span>{{view.order_counts}}</span>
								</p>
							</div>
							<div class="say">
								<p>产品经理说：</p>
								<div style="padding-right:30px">
									{{view.pm_note | ellipsis}}
								</div>
							</div>
							<!-- <img src="../assets/other/lqyh.png" @click="opengetCouponList" class="lq"> -->
						</div>
						<div class="order">
							<div class="top flex-between">
								<div>
									<span class="t1">价格</span>
									<span  class="t2">¥{{nowprice.price}}</span>
									<span  class="t1">起</span>
									<span  class="t3">起价说明</span>
									<div>
										<img src="../assets/other/whicon.png" alt="" @mouseover="jf=1"
									@mouseleave="jf=0">
										<div class="sm" v-show="jf==1">
											<p>本起价是可选活动日期中，按单人出行核算的最低单人价格。 </p>
											<p> 产品价格会根据您所选活动日期、出行人数、游客类型以及所选附加项目的不同而有所差别。</p>
										</div>
									</div>

								</div>
								<!-- <p>
									<img src="../assets/other/ewmicon.png" alt="">
									<span  class="t4">扫码报名</span>
								</p> -->
							</div>
							<div class="num" v-if="radioList && radioList.length > 1">
								<span class="n1">集合点</span>
								<span class="n1"></span>
								<span>
									<el-radio
										v-model="radio"
										:label="index"
										v-for="(item,index) in radioList"
										:key="index"
										:disabled="inventoryNumber(item) <= 0"
										@change="changeRadio($event)"
									>
									{{ item.place_address ? item.place_address.join(',') : item.place_address}}</el-radio>
								</span>
							</div>
							<div class="num">
								<p>
									<span class="n1">出行人数</span>
									<el-input-number v-model="adult"
									:min="0" :max="10" size="mini"></el-input-number>
									<span>成人</span>
								</p>
								<p>
									<el-input-number v-model="child"
									:min="0" :max="10" size="mini"></el-input-number>
									<span>儿童</span>
									<span class="tip">12周岁(不含)以内</span>
								</p>
							</div>
							<div class="num">
								<span class="n1">总 价</span>
								<span class="n1"></span>
								<span class="price">¥{{totalprice}}</span>
							</div>
							<div class="buts">
								<p class="b1 but" @click="tourl(nowprice.id)">立即报名</p>
								<p class="b1" @click="dingzhi">我要定制</p>
								<p class="b2" >
									<img src="../assets/other/collection.png" alt="" v-if="view.is_collection==0" @click="sc(1)">
									<img src="../assets/2_p.png" v-else @click="sc(2)">
									<span>收藏</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="signup">
				<div class="sign">
					<p>报名情况</p>
					<p>已有<span>{{ofpeople.count}}</span>人报名</p>
				</div>
				<div class="imgs">


					<div class="img" v-for="(item,i) in moreNumberlist" :key="i">
						<div class="tou">
							<img :src="item.avatar" alt="">
							<div class="bgtext" v-if="item.people_count>=2">{{item.people_count}}</div>
						</div>
						<p>{{item.account | wuellipsis(3)}}</p>
					</div>

				</div>
				<div class="more" @click="dialogVisible = true">
					更多
				</div>
			</div>

			<div class="menus">
				<ul>
					<li :class="{'active':activeClass==0}" @click="getActiveClass(0)">活动亮点</li>
					<li :class="{'active':activeClass==1}" @click="getActiveClass(1)">活动行程</li>
					<li :class="{'active':activeClass==2}" @click="getActiveClass(2)">活动费用</li>
					<li :class="{'active':activeClass==3}" @click="getActiveClass(3)">活动须知</li>
					<li :class="{'active':activeClass==4}" @click="getActiveClass(4)">退改规则</li>
					<li :class="{'active':activeClass==5}" @click="getActiveClass(5)">活动点评</li>

				</ul>
			</div>

			<div class="roll m1">
				<h2>活动亮点</h2>
				<div v-html="view.activities_content">

				</div>

			</div>
			<div class="roll m2">
				<h2>
					活动行程<span>以下行程时间均为当地时间</span>
				</h2>
				<div class="timer">
					<activeDay :list="daylist"></activeDay>
				</div>
				<!-- <div class="boxtext" style="margin-top:20px">
					<h3>行程备注：</h3>
					<div v-html="notes">

					</div>
				</div> -->
			</div>

			<div class="roll">
				<h2>活动费用</h2>
				<div class="boxtext">
					<!-- <div v-for="(it,i) in view.system_content_template_tree_1" :key="i">
						<h3>{{it.name}}：</h3>
						<div v-html="it.content"></div>
					</div> -->
					<div v-html="view.activities_cost"></div>
					<div v-html="view.activities_cost_other"></div>
				</div>
			</div>

			<!--  -->
			<div class="roll">
				<h2>活动须知</h2>
				<div class="boxtext" style="margin-bottom:20px">
					<h3>行程备注</h3>
					<div v-html="notes"></div>
				</div>
				<div class="boxtext" style="margin-bottom:20px" v-for="(item,i) in activeNotice" :key="i">
					<h3>{{item.name}}</h3>
					<div v-html="item.content"></div>
				</div>
			</div>
			<div class="roll">
				<h2>退改规则</h2>
				<div v-html="view.back_price_rules">

				</div>
			</div>
			<div class="roll" v-if="view.attachment_list">
				<div style="display:flex;align-items: center;">
					<h2>出行清单</h2>
					<p style="font-size:14px;margin-left:15px;color:#333333;"><span>*</span>以下文件仅供收藏或打印分享或看着玩</p>
				</div>

				<div class="qd">
					<img src="../assets/public/qd.png" alt="">
					<span>准备清单</span>
					<button class="down" @click="down">点击下载</button>
				</div>
			</div>
			<div class="roll m5">
				<h2>活动点评</h2>

				<div class="comment" v-for="(item,i) in goodsCommentList" :key="i">

						<div class="tou">
							<img :src="item.avatar" class="t">
							<!-- <img src="../assets/other/vip.png" class="v"> -->
						</div>
						<div class="text">
							<div class="userimg">
								<p>{{item.account}}</p>
								<p>{{item.create_date}}</p>
							</div>
							<div class="usermsg">
								<p>{{item.content}}</p>
								<ul v-if="item.img">
									<template v-for="it in item.img" >
										<li :key="it" v-if="it">
											<!-- <img :src="it" alt=""> -->
											 <el-image

												:src="it"
												:preview-src-list="item.img">
											</el-image>
										</li>
									</template>

								</ul>
							</div>
							<div class="qumsg" v-if="item.reply_conent!=''">
								<p>
									<span>趣游回复：</span>
									{{item.reply_conent}}
								</p>
							</div>

						</div>
				</div>
				<div v-if="goodsCommentList.length==0" style="text-align:center;color:#999">- 当前活动暂无点评 -</div>
			</div>

			<div class="roll m5">
				<div class="comment" v-for="(item,i) in questionlist" :key="i">

						<div class="tou">
							<img :src="item.avatar" class="t">
							<!-- <img src="../assets/other/vip.png" class="v"> -->
						</div>
						<div class="text">
							<div class="usercomment">
								<p>{{item.account}}</p>
								<p>{{item.create_date}}</p>
							</div>
							<div class="usermsg">
								<p>{{item.question}}</p>

							</div>
							<div class="qumsg">
								<p>
									<span>趣游回复：</span>
									{{item.answer}}
								</p>
							</div>
						</div>
				</div>
				<!-- <div id="page">
					<el-pagination

					  layout="prev, pager, next"
					  :total="100">
					</el-pagination>
				</div> -->

			</div>
		</div>

		<el-dialog title="报名情况"  :visible.sync="dialogVisible" width="800px">
			 <div class="dialogmsg">
				 <div class="sex">
					 <p>共报名 <span>{{ofpeople.count}}人</span></p>
					 <p>
						 <img src="../assets/other/man.png" alt="">
						 男：<span>{{ofpeople.count_1}}人</span>
					 </p>
					 <p>
						 <img src="../assets/other/woman.png" alt="">
						 女：<span>{{ofpeople.count_2}}人</span>
					 </p>
				 </div>
				<div class="imgs">
					<div class="img" v-for="(item,i) in moreNumberlist" :key="i">
						<div class="tou">
							<img :src="item.avatar" alt="">
							<div class="bgtext" v-if="item.people_count>=2">{{item.people_count}}</div>
						</div>
						<p>{{item.account}}</p>
					</div>
				</div>
			 </div>

		</el-dialog>
		<el-dialog title="领取优惠券"  :visible.sync="diacoupon" width="500px">
			 <CouponItem
				v-for="item in couponList"
				:key="item.id"
				:data="item"
				@onReceive="onClick"
				/>
				<view style="text-align:center;line-height:100px" v-if="couponList.length=='0'"> - 暂无数据 -  </view>
		</el-dialog>
		<myfooter></myfooter>
		<myright></myright>
	</div>
</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
import activeDay from "@/components/com/activeDay.vue"
import CouponItem from "@/components/com/CouponItem.vue"
import {goodsViewAPI,questionAnswerListAPI,questionAddAPI,goodsCommentListAPI,moreNumberAPI,
travelnotesAPI,tripListAPI,getActivityCouponList} from "@/api/details"
import {collectionTakeDelAPI} from "@/api/trivialShot"
export default {
  components: {
    myheader, myfooter, myright,activeDay,CouponItem
  },
  data () {
    return {
		jf:0,
      keyword: '',
      imgArr: [],
      value: 3,
      num: 1,
      activeClass: 0,
      dialogVisible: false,
	  id:null,
	  view:{},
	  adult:1,
	  child:0,
	  nowprice:{},
	  acitveDay:0,
	  questionlist:[],
	  goodsCommentList:[],
	  moreNumberlist:[],
	  ofpeople:{},
	  myquestion:"",
	  icons:["el-wu-icon6","el-wu-icon1","el-wu-icon2","el-wu-icon3","el-wu-icon4","el-wu-icon5","el-wu-icon6"],
	  notes:"",
	  calendarData: [],
		vipimg:[],
		daylist:[],
		lytime:false,
		diacoupon:false,
		couponList:[],
		activeNotice:[],
		radio: 0,
		radioList: [],
    }
  },
  created () {
    const id = this.$route.query.id
    this.id=id
	this.getdetails(id)
	this.getImage()
  },
  filters:{
	  type(i){
		//   1=城市微游；2=境外旅行；3=国内长途；4=周边活动；5=趣·户外；6=趣·旅游
		  var arr=["","城市微游","境外旅行","国内长途","周边活动","趣·户外","趣·旅游","趣·跟团"]
		  return arr[i]
	  },
	  triptype(i){
		//   1  2   4  5
		var arr=["","交通","景点","餐饮","住宿","其它"]
		  return arr[i]
	  },
	  stu(s){

      if (s) {

        return s.replace(/.00/gi,'')+"起"
      }
        return ""
    },
    stu2(s){
      // 是否成团：1=是;0=否 2 取消
      switch(s){
        case 0:
          return "未成团"
         case 1:
          return "已满团"
         case 2:
          return "取消"
      }
    },
	ellipsis: function(value) {
		if (!value) return "";
		if (value.length >120) {
		return value.slice(0,120) + "...";
		}
		return value;
	}
  },
  computed:{
	  brightDate () {
      let ary = []
      for (let i in this.calendarData) {
        ary.push(this.calendarData[i].startDate)
      }
      return ary
    },
	 totalprice(){
		var a=this.adult*this.nowprice.adult_price
		var c=this.child*this.nowprice.children_price
		return a+c
	 },
  },
  mounted () {
	  this.getquestionlist()
	  this.getgoodsCommentList()

	 this.gettravelnotes()
  },
  methods: {
	inventoryNumber (obj) {

        let total = Number(obj.total_inventory || 0)
        let leader = Number(obj.leader_inventory || 0)
        let enrollment = Number(obj.enrollment_number || 0)
        return total - leader - enrollment

    },
	async getCouponList() {
      const res = await getActivityCouponList({goods_id: this.id})
        this.couponList =  res.couponList
		this.diacoupon=true

    },
	opengetCouponList(){
		this.getCouponList()
	},
	onClick(flag){
		this.diacoupon=flag
	},
	  down(){
		  tripListAPI({id:1}).then(res=>{
			  const ele = document.createElement('a');
            ele.setAttribute('href', res.tripList.note);
			ele.setAttribute('target', "_blank");
            ele.style.display = 'none';
            document.body.appendChild(ele);
            ele.click();
            document.body.removeChild(ele);
		  })
	  },
	  sc(n){
		  if (!localStorage.getItem('token')) {
			  this.$message.info("请先登录！")
			  return
		  }
		  var data={
			  tid:this.id,
			  status:n,
			  type:1
		  }
		  collectionTakeDelAPI(data).then(res=>{
			  this.$message.success(n==2 ? "取消收藏" : "收藏成功")
			  this.getdetails(this.id)
		  })
		 },
	  getImage() {
		for (let index = 1; index < 6; index++) {
			this.vipimg.push(require('../assets/vip/vip' +index+ '.png'))
		}
	  },
	changeRadio(e) {
		this.nowprice = this.radioList[e]
		this.getmoreNumber(this.nowprice.id)
	},
	getRadioList(date) {
		this.radioList = this.view.goods_price && this.view.goods_price.filter(el => {
			if (el.buy_date == date) return el
		})
	},
	show(date){
		const select = this.canSelectDate(date)
		if (select.length) {
			if (this.canSelectDateTotal(date) <= 0 && this.canSelectDate(date).every(i => i.is_team==1)) {
				this.$message.info("该批次已满团，无法继续报名")
			} else {
				const index = select.findIndex(i => this.inventoryNumber(i) > 0)
				const obj = select[index]

				this.radio = index
				this.getRadioList(obj.buy_date)
				this.nowprice=obj
				this.getmoreNumber(obj.id)
			}
		}else{
			this.$message.info("该日暂无活动")
		}
		// var obj=this.getprice(date)
		// console.log(obj)
    // if(obj){
    //   if (this.inventoryNumber(obj)==0 && obj.is_team==1) {
		// 	}else{
		// 		this.radio = 0
		// 		this.getRadioList(obj.buy_date)
		// 		this.nowprice=obj
		// 		this.getmoreNumber(obj.id)
		// 	}
        //  if(obj.is_team==0){

        //  }else if (obj.is_team==1) {
		// 	  this.$message.info("该批次已成团，无法继续报名")
		//  }else{
        //     this.$message.info("请选择未成团日期")
        //  }

	},
	canSelectDate (date) {
		const list = this.calendarData.filter(i => i.buy_date === date)
		return list
	},
	canSelectDateTotal (date) {
		return this.canSelectDate(date).reduce((total, i) => total + this.inventoryNumber(i), 0)
	},
	getprice (date) {
		let content = ''
		for (let i in this.calendarData) {
			if (date === this.calendarData[i].buy_date) {
				content = this.calendarData[i]
				break
			}
		}
		return content
	},
	gettravelnotes(){
		travelnotesAPI({goods_id:this.id}).then(res=>{
			this.notes=res.cateView.note
		})
	},
	getActiveClass (index) {
      this.activeClass = index
      const jump = document.querySelectorAll('.roll')
      jump[index].scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    tourl (id) {
      this.$router.push({ path: '/signup',query:{id}  })
    },
	dingzhi(){
		this.$router.push({ path: '/teamCustomization' })
	},
    async	getdetails(id){
		var data={
			id
		}
		if (this.$route.query.is_admin==1) {
			data.is_admin=1
		}

		goodsViewAPI(data).then(res=>{
			this.view=res.cateView
			 document.title = this.view.goods_name
			 var data={
				 adult_price:0,
				 children_price:0,
				 price:0
			 }
			 this.nowprice=res.cateView.goods_price.length==0 ? data : res.cateView.goods_price[0]
			this.calendarData=res.cateView.goods_price

			var arr=res.cateView.goods_trip==[] ? [] : res.cateView.goods_trip[0].content
		    this.daylist= JSON.parse(arr);
			this.getmoreNumber(res.cateView.goods_price[0].id)
			// this.moreNumberlist=res.cateView.goods_price[0].id
			var list=res.cateView.system_content_template_tree_2

			const activities_notice = {
				content: this.view.activities_notice,
				name: '注意事项',
				id: Math.floor(Math.random() * 10000)
			}
			this.activeNotice.unshift(activities_notice)
			this.activeNotice=	this.activeNotice.concat(list)
			this.activeNotice.splice(3,1)

		})
	},
	getquestionlist(){
		questionAnswerListAPI({id:this.id}).then(res=>{
			 this.questionlist=res.cateList.data

		})
	},
	upquestion(){
		if (this.myquestion=="") {
			this.$message.info("请填写你的留言内容")
			return
		}
		if (this.lytime) {
			this.$message.info("请稍后留言")
			return
		}
		questionAddAPI({goods_id:this.view.id,question:this.myquestion}).then(res=>{
			this.myquestion=""
			this.lytime=true
			setTimeout(()=>{
				this.lytime=false
			},30000)
			this.$message({
				type:"success",
				message:"留言成功"
			})
		})
	},
	getgoodsCommentList(){
		goodsCommentListAPI({id:this.id}).then(res=>{
			 this.goodsCommentList=res.cateList
		})
	},
	getmoreNumber(id){
		moreNumberAPI({id,page: 1,pageSize: 1000}).then(res=>{
			this.moreNumberlist=res.cateList
			this.ofpeople=res.ofpeople
		})
	},


  }
}
</script>

<style lang="scss" scoped="scoped">
	@import url('../components/icon.css') ;
	::v-deep .el-radio__input.is-checked .el-radio__inner {
		border-color: #ff7c00;
		background: #ff7c00;
	}
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #ff7c00;
	}
	.dialogmsg{
		.sex{
			display: flex;
			p{
				display: flex;
				align-items: center;
				margin-right: 20px;
				font-size: 16px;
				color: #333;
				span{
					color: #FF7C00;
				}
				img{
					margin: 0 5px;
				}
			}

		}
		.imgs{
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0;
			.img{
				width: 50px;
				margin: 8px 13px;
				.tou{
					position: relative;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					overflow: hidden;
					img{
						width: 50px;
						height: 50px;
					}
					.bgtext{
						position: absolute;
						left: 0;
						bottom: 0;
						width:50px;
						height: 18px;
						background: rgba(0,0,0,0.70);
						color: #fff;
						text-align: center;
						font-size: 12px;
						line-height: 18px;
					}

				}
				p{
					text-align: center;
					font-size: 12px;
					margin-top: 5px;
					color: #666666;
					line-height: 17px;
				}
			}
		}
	}
#details{

	ul{
		.el-carousel__button{

			height:6px;
			border-radius: 4px;
			opacity:1;
			width:6px;
			background-color: #fff;
		}
		.is-active .el-carousel__button{
			background-color: #ff7c00;
			width:30px;
		}
	}

.bg{
	height: 10px;
	min-width: 1200px;
	width: 100%;
	background: linear-gradient(#ececec,#fff);
}
.details{
	width: 1200px;
	margin: 0px auto;
}
.title{
	display: flex;
	align-items: center;
	margin: 10px 0 15px;
	font-size: 15px;
	p{
		color: #666666;
	}

	line-height: 21px;
	img{
		width: 10px;
		height: 11px;
		margin: 0 10px;
	}
}
.detail{
	display: flex;
	.dleft{
		width: 490px;
		.pic{
			width: 100%;
			min-height: 276px;
		}
		.calendar{
			margin-top: 20px;
			background: #f7f7f7;
			border: 1px solid #e6e6e6;
			border-radius: 6px;
			width: 490px;
			overflow-y:hidden;
		}

	}
	.dright{
		flex: 1;
		margin-left: 20px;
		height: 500px;

		.info{
			height: 320px;
			position: relative;
			h3{
				font-size: 22px;
				font-weight: 600;
				line-height: 34px;

			}
			.label{
				ul{
					display: flex;
					margin-top: 15px;
					li{
						border: 2px solid #ff7c00;
						border-radius: 5px;
						font-size: 15px;
						padding: 0 10px;
						color: #ff7c00;
						line-height: 28px;
						margin-right:15px;
						font-weight: 500;
					}
				}
			}
			.star{
				margin: 10px 0;
				display: flex;
				flex-wrap: wrap;
				p{
					font-size: 14px;
					line-height: 30px;
					display: flex;
					margin-right: 40px;
					align-items: center;
					>span{
						margin-right: 15px;
					}

				}
			}
			.adress{
				p{
					display: flex;
					align-items: center;
					margin-right: 20px;
					img{
						width: 19px;
						height: 19px;
					}
					span{
						font-size: 16px;
						font-weight: 600;
						color: #ff7c00;
						line-height: 22px;
						margin: 0 3px;
					}
					.t{
						margin-left: 6px;
						color: #333333;
					}
				}

			}
			.say{
				margin-top: 10px;
				p{
					font-size: 14px;
					color: #333333;
					line-height: 30px;
					font-weight: 500;
				}
				div{
					p{
						color: #666;
						line-height: 28px;
						font-weight: 400;
					}
				}
			}
			.lq{
				position: absolute;
				right: -20px;
				bottom: 10px;
			}
		}

		.order{
			margin-top: 20px;
			width: 692px;
			// height: 230px;
			padding-bottom: 20px;
			background: #ffffff;
			border: 1px solid #ff7c00;
			border-radius: 6px;
			.top{
				height: 45px;
				padding: 0 20px;
				// opacity: 0.1;
				background:rgba(255,124,0,0.1);
				>p{
					display: flex;
					align-items: center;
				}
				>div{
					display: flex;
					align-items: center;
					>div{

						position: relative;
						img{
							margin-top: 4px;
						}
						.sm{
							z-index: 10;
							position: absolute;
							top: 30px;
							left: 5px;
							width: 185px;
							// height: 187px;
							opacity: 1;
							background: rgba(0,0,0,0.50);
							border-radius: 4px;
							padding: 5px;
							p{
								font-size: 12px;
								color: #fff;
								line-height: 18px;
								padding: 8px;
								padding: 0;
							}
						}
					}
				}
				span{
					font-size: 14px;
					margin: 0 3px;
					color: #ff7c00;
					line-height: 20px;
				}
				.t2{
					font-size: 18px;
					font-weight: 500;
				}
				.t3{
					color: #666666;
					margin-left: 15px;
				}
				.t4{
					color: #000000;
				}
			}

			.num{
				display: flex;
				padding-left: 20px;
				margin-top: 22px;
				align-items: center;
				>p{
					margin-right: 25px;
					>span{
						margin-left: 5px;
						color: #666666;
					}
				}

				.tip{
					font-size: 12px;
					color: #666666;
				}
				.n1{

					margin-right: 20px;
				}
				.price{
					font-size: 24px;
					font-weight: 600;
					color: #ff4c12;
					line-height: 33px;
				}
			}

			.buts{
				display: flex;
				align-items: center;
				margin-top: 15px;
				.b1{
					width: 169px;
					height: 45px;
					background: #f4a622;
					border-radius: 6px;
					font-size: 18px;
					margin-right: 15px;
					text-align: center;
					color: #ffffff;
					line-height: 45px;
				}
				.but{
					background: #FF4C12;
					margin-left: 110px;
				}
				.b2{
					display: flex;
					align-items: center;
					font-size: 14px;

					color: #666666;
					line-height: 20px;
					img{
						width: 30px;
						margin-right: 5px;
					}
				}
			}
		}

	}
}
.signup{
	position: relative;
	width: 1200px;
	margin: 20px auto;
	height: 90px;
	background: #f7f7f7;
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding:10px 15px;
	padding-right:120px;
	.sign{
		width: 150px;
		p{
			font-size: 16px;

			color: #333333;
			line-height: 30px;
			span{
				color: #FF4C12;
				margin: 0 3px;
			}
		}
	}
	.imgs{
		flex: 1;
		display: flex;
		overflow: hidden;
		.img{
			width: 50px;
			margin: 0 13px;
			.tou{
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				overflow: hidden;
				img{
					width: 50px;
					height: 50px;
				}
				.bgtext{
					position: absolute;
					left: 0;
					bottom: 0;
					width:50px;
					height: 18px;
					background: rgba(0,0,0,0.70);
					color: #fff;
					text-align: center;
					font-size: 12px;
					line-height: 18px;
				}

			}
			p{
				text-align: center;
				font-size: 12px;
				margin-top: 5px;
				color: #666666;
				line-height: 17px;
			}
		}
	}
	.more{
		position: absolute;
		right: 10px;
		top: 2px;
		font-size: 14px;
		margin-top: 5px;
		color: #666666;
		line-height: 17px;
		cursor: pointer;
	}
}
.menus{
	position:sticky;
	top:-1px;
	width: 1200px;
	margin: 20px auto;
	border-bottom: 2px solid #cccccc;
	background-color: #fff;
	z-index: 999;
	ul{
		display: flex;

		li{
			font-size: 18px;
			margin: 10px 45px 10px 0;
			color: #666666;
			line-height: 25px;
			cursor: pointer;

		}
		.active{
			color: #FF7C00;
		}
	}
}
.roll{
	width: 1200px;
	margin: 20px auto;

	h2{
		font-size: 24px;
		font-weight: 500;
		color: #333333;
		line-height: 33px;
		margin: 10px 0;
	}
}
.qd{
	width: 260px;
	height: 56px;
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 1px 8px 0px #000000;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	justify-content: space-between;
	margin-top: 20px;
	span{
		color: #333333;
		font-size: 16px;

	}
	.down{
		width: 90px;
		height: 32px;
		background: #ff4c12;
		border-radius: 4px;
		color: #ffffff;
		line-height: 32px;
		text-align: center;

	}
}
.boxtext{
	background: #f9f9f9;
	border-radius: 10px;
	padding: 15px 20px 5px 0;
	line-height: 25px;
	h3{
		border-left: 3px solid #FF7C00;
		padding-left: 17px;
		margin-bottom: 10px;
	}
	div{
		padding-left: 20px;
		padding-bottom: 10px;
		p{
			color: #666666;
			color: 16px;
			line-height: 30px;
			font-size: 16px;
		}

	}
}
.m1{
	img{
		width: 100%;
		margin-bottom: 10px;
		border-radius: 3px;
	}
}
.m2{
	h2{
		span{
			font-size: 16px;
			font-weight: 400;
			color: #666666;
			line-height: 33px;
			margin-left: 25px;
		}
	}
	.timer{
		display: flex;

	}

}
.m5{
	.comment{
		display: flex;
		margin-top: 15px;
		.tou{
			position: relative;
			width: 50px;
			height: 50px;
			.t{
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
			.v{
				position: absolute;
				left: 0;
				bottom:-5px;
				width:50px;
			}
		}
		.text{
			margin-left: 20px;
			flex: 1;

			.useusercommentr{
				box-sizing: border-box;
				p:nth-child(1){
					font-size: 16px;
					font-weight: 500;
					color: #333333;
					line-height: 30px;
				}
				p:nth-child(2){
					font-size: 13px;

					color: #666;
					line-height: 20px;
				}

			}
			.usermsg{
				margin-top: 15px;
				line-height: 30px;
				ul{
					display: flex;
					li{
						margin-right: 20px;
						max-width: 160px;
						height: 100px;
						overflow: hidden;
						img{
							height: 100px;
						}
					}
				}
			}
			.qumsg{
				margin-top: 15px;
				line-height: 30px;
				padding:10px 20px;
				background: #f9f9f9;
				width: 100%;
				span{
					color: #FF7C00;
				}
			}
		}

	}
}
.m6{
	// width: 1200px;
	// height: 150px;
	position: relative;
	textarea{
		width: 1200px;
		height: 150px;
		background: #ffffff;
		border: 2px solid #cccccc;
		border-radius: 6px;
		line-height: 30px;
		text-indent: 10px;
		padding: 5 15px;
		font-size: 16px;
		color: #666;
	}
	button{
		position: absolute;
		bottom:4px;
		right: 0;
		width: 117px;
		height: 45px;
		background: #ff7c00;
		border-radius: 6px 0px 0px 0px;
		border: none;
		font-size: 18px;
		color: #fff;
	}

}
#page{
	margin: 30px auto;
	width: 400px;

	.el-pager .active{
		background-color: #ff7c00 !important;
	}
	.el-pager li.active{
		color: #fff;
	}
	.number:hover{
		color: #ff7c00;
	}
}
// 本页结束
.el-input-number .is-disabled{
	margin-left: 0 !important;

}
}

</style>

<style lang="scss">
*{
	color:unset;
}
.el-carousel__indicators--horizontal {
		bottom: 0;
		// left: 100px;
		transform: translateX(-50%);
		width: 100%;
		display: flex;
		justify-content: center;

	}
.calendar{

	.el-button--mini:nth-child(1){
		display: none;
	}
	.everyDay {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #409eff;
	color: #fff;
	border-radius: 50%;

	}
	p{
	font-size: 12px;
	line-height: 20px;
	}
	.el-calendar__header{
	padding: 6px 10px;
	}
	.el-calendar-table .el-calendar-day{
	padding:5px 3px !important;
	height: 70px !important;
	}
	.el-calendar__body{
	padding: 5px !important;
	}
	.el-calendar-table thead th{
	padding:5px !important;
	font-size: 12px;
	}
	#calendar{
	    width: 480px;
		.el-button-group>.el-button:not(:first-child) {
			margin-right: -1px;
			/* display: none; */
		}
	}


}
</style>

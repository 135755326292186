<template>
  <div class="flex-c coupon-item">
    <div class="coupon-juan">
      <div class="title">{{node.coupon_name}}</div>
      <div class="price-title">¥{{node.coupon_price}}</div>
    </div>
    <div class="coupon-juan">
      <div class="sub-title">{{node.extra}}</div>
      <div class="receive-btn" @click="receive">领取</div>
    </div>
    <div class="line-style" />
    <div class="coupon-juan">
      <div :class="[isShow ? 'rule-title-show' : 'rule-title-hidden']">规则：{{node.extra_note}}</div>
      
    </div>
  </div>
</template>

<script>
import {receiveCouponAPI } from "@/api/details"
export default {
  name: "CouponItem",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
      return {
          isShow:false
      }
  },
  computed: {
    node() {
      return this.data
    }
  },
  methods: {
    onClickShow() {
        console.log("展开隐藏");
      if (this.isShow) {
          this.isShow = false;
      }else{
          this.isShow = true;
      }
    },
    receive() {
      receiveCouponAPI({coupon_id:this.node.coupon_id}).then(res=>{
        this.$message.success("领取成功！")
        this.$emit('onReceive', false)
      })
      
    },
    onCancel() {
      this.$emit("modalClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-item {
    
    padding:10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   
    background: #FFE8D9;
    border: 1px solid #FF9671;
    border-radius: 10px;
    margin-bottom: 20px;
    .coupon-juan{
      width:100%; 
      margin-top: 10px;
      display:flex;
      justify-content: space-between; 
      align-items: center;
      .title {
        color: #333333;
        font-size: 20px;
        font-weight: 500;
         
      }
      .price-title{
         width: 108px;
        color: #FF4C12;
        font-size: 20px;
        font-weight: 500;
        text-align: center; 
      }
      .sub-title {
        color: #333333;
        font-size: 16px; 
       
        overflow: hidden; 
        text-overflow: ellipsis;
        display: -webkit-box; 
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .receive-btn {
        background: linear-gradient(131deg,#ffb300 0%, #ff7c00 100%);
        width: 108px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        border: none;
        border-radius: 20px;
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
      }
      .rule-title-hidden {
        color: #5C5754;
         
        
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .rule-title-show {
        color: #5C5754;
        
      }
      .title-org{
        color: orange;
        
        text-align: center;
        
      }
      .ic-up-img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .line-style {
    margin-top:10px;
    margin-bottom: 0px;
    
    height: 1px;
    border-top: 1px dashed #FF9671;
  }
</style>